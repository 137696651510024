<template>
  <div class="blog-subcategories">
    <div :class="{'blog-subcategories__item': true, 'blog-subcategories__item--active': current === elem.id }" v-for="elem of subcategories" :key="elem.id" @click="goToSubcategy(elem.attributes.subcategory_path)">
      {{ elem.attributes.name }}
    </div>
    <router-link :class="{'blog-subcategories__item': true, 'blog-subcategories__item--active': href.includes('theflyreport/argus') }"  :to="'/theflyreport/argus'">Monitor</router-link>
  </div>
</template>

<script>
  // import SvgIcon from '@/components/common/SvgIcon/SvgIcon';
  import './BlogSubcategories.styl';

  export default {
    name: 'BlogSubcategories',
    props: ['current'],
    components: {
      // SvgIcon,
    },
    computed: {
      subcategories () {
        return this.$store.state.blog.subcategories.filter(({ attributes }) => attributes.showInHeader);
      },
      href () {
        return window.location.href
      },
    },
    methods: {
      goToSubcategy (subcategoryPath) {
        this.$router.push(`/theflyreport/subcategories/${subcategoryPath}`)
      },
    },
  };
</script>
