<template>
  <div class="box box--blog">
    <div class="box__inner box__inner--blog">
      <div class="blog-search">
        <BlogSubcategories />
        <button class="blog-search__button" type="button" @click="$router.push('/theflyreport/search')">
          <div v-if="$store.state.view.theme === 'dark'">
            <SvgIcon name="magnifier-light"/>
          </div>
          <div v-if="$store.state.view.theme === 'light'">
           <SvgIcon name="magnifier-dark"/>
          </div>
        </button>
      </div>
      <div class="blog-interface">
        <BlogList/>
        <BlogFeed/>
      </div>
    </div>
  </div>
</template>

<script>
  import SvgIcon from '@/components/common/SvgIcon/SvgIcon';
  import BlogList from '@/components/Blog/BlogList/BlogList';
  import BlogFeed from '@/components/Blog/BlogFeed/BlogFeed';
  import BlogSubcategories from '@/components/Blog/BlogSubcategories/BlogSubcategories';
  import './Blog.styl';

  export default {
    name: 'Blog',
    components: {
      SvgIcon,
      BlogList,
      BlogFeed,
      BlogSubcategories,
    },
    async mounted () {
      this.$store.dispatch('getFeed');
      await this.$store.dispatch('getGrid');
      if (this.$route.query.category) {
        document.querySelector(`a[name=${this.$route.query.category}]`).scrollIntoView()
      }
      this.checkSubcategory('Travel');
      this.checkSubcategory('Hotels');
      this.checkSubcategory('Tech');
    },
    methods: {
      checkSubcategory (subcategoryName) {
        const blog = this.$store.state.blog.grid;
        const addSubcategory = blog.filter(g => g.posts.results.filter(p => (p.subcategories || p.attribtes.subcategories).filter(s => s.name === subcategoryName).length > 0).length > 0).length > 0;
        if (addSubcategory) {
          this.$store.dispatch('showSubcategory', subcategoryName);
        } else {
          this.$store.dispatch('hideSubcategory', subcategoryName);
        }
      },
    },
    metaInfo: {
      title: 'The FLY Report',
      meta: [{
         name: 'description', content: 'FLYJETS\' digital newspaper about aviation, space, future flight, eVTOLs, renewables and travel.',
      }],
    },
  };
</script>
