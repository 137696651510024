<template>
  <div class="blog-list">
    <div class="blog-list__wrapper">
      <div class="blog-list__back" @click="$router.push('/theflyreport')" v-if="title">
        <SvgIcon name="up-arrow" />
        <span>Back to News</span>
      </div>
      <div class="blog-list__header" v-if="title">{{ title }}</div>
      <div class="blog-list__date">{{new Date().toLocaleDateString('en-us', { weekday:"long", year:"numeric", month:"short", day:"numeric"})}}</div>
      <vue-scroll :ops="ops">
        <div class="blog-list__list">
          <div class="blog-list__section" v-for="item of blog" :key="item.id">
            <a class="blog-list__title" :name="item.title">{{item.title}}</a>
            <div class="blog-list__item">
              <BlogItem v-for="elem of item.posts.results" :key="elem.id" :elem="elem"/>
            </div>
            <div class="blog-list__load-more" @click="loadMore(item.id)" v-if="item.title !== 'Top' && item.posts.pagination.page < item.posts.pagination.pageCount">Load more</div>
          </div>
        </div>
      </vue-scroll>
    </div>
  </div>
</template>

<script>
  import BlogItem from '@/components/Blog/BlogItem/BlogItem';
  import SvgIcon from '@/components/common/SvgIcon/SvgIcon';
  import './BlogList.styl';
  import moment from 'moment';

  export default {
    name: 'BlogList',
    props: ['title', 'subcategoryId'],
    data: () => ({
      ops: {
        rail: {
          size: '4px',
        },
        bar: {
          size: '4px',
          background: 'var(--scroll-bar-background)',
        },
      },
    }),
    computed: {
      blog () {
        let blog = this.$store.state.blog.grid;
        blog = blog.filter(({posts = []}) => posts.results.length > 0);
        blog = blog.map(item => {
            item.posts.results = item.posts.results.map(article => {
              let categories = (article.categories || article.attributes.categories.data).filter(c => (c.name || c.attributes.name) !== 'Top').map(c => c.name).join(' • ');
              const subcategories = (article.subcategories || article.attributes.subcategories.data).filter(s => s.name !== 'Today' || moment(article.publishedAt).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')).map(s => (s.name || s.attributes.name)).join(' • ');
              if (categories && subcategories) {
                categories += ' • ';
              }
              return {...article, categoriesSubcategories: (categories + subcategories)}
            });
          return item;
        });
        // blog = blog.map(item => {
        //   if (item.title !== 'Top') {
        //     item.posts.results = item.posts.results.filter(article => article.categories.filter(c => c.name === 'Top').length === 0);
        //   }
        //   return item;
        // });
        // if (this.title === 'Today') {
        //   blog = blog.map(item => {
        //     item.posts.results = item.posts.results.filter(article => {
        //       let today = new Date();
        //       let updatedAt = new Date(article.updatedAt);
        //       today = new Date(today.getFullYear(), today.getMonth(), today.getDate());
        //       updatedAt = new Date(updatedAt.getFullYear(), updatedAt.getMonth(), updatedAt.getDate());
        //       return today.getTime() === updatedAt.getTime();
        //     });
        //     return item;
        //   });
        // }
        return blog;
      },
    },
    methods: {
      loadMore (categoryId) {
        const data = {category: categoryId};
        if (this.subcategoryId) {
          data.subcategory = this.subcategoryId;
        }
        this.$store.dispatch('loadMoreGrid', data)
      },
    },
    components: {
      BlogItem,
      SvgIcon,
    },
  };
</script>
